<template>
  <div class="suppliers-list pd-20">
    <a-page-header class="mb-10 pd-0" sub-title="">
      <div slot="title">
        <h1>Fornecedores</h1>
      </div>

      <div slot="extra">
        <a-button
          icon="edit"
          type="primary"
          @click="$router.push('/suppliers/create')"
          ghost
        >
          Novo Fornecedor
        </a-button>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mt-0 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row>
          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Nome</label>
              <a-input
                v-model="supplier.filters.name"
                @change="getSuppliers"
                placeholder="Localizar..."
              />
            </div>
          </a-col>
          <a-col class="a-right" :span="4" :offset="16"> </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table mt-10"
      rowKey="id"
      :columns="suppliersTableColumns"
      :data-source="supplier.list"
      :loading="supplier.loading"
      :pagination="false"
      @change="supplierTableChange"
    >
      <template slot="id" slot-scope="supplier">
        {{ supplier.id }}
      </template>
      <template slot="name" slot-scope="supplier">
        {{ `${supplier.first_name} ${supplier.last_name}`.toUpperCase() }}
      </template>
      <template slot="created" slot-scope="supplier">
        {{ formatDate(supplier) }}
      </template>
      <span class="table-actions" slot="action" slot-scope="supplier">
        <a-tooltip placement="top" title="Editar">
          <a class="edit" @click="editSupplier(supplier.id)">
            <a-icon type="edit-svg" />
          </a>
        </a-tooltip>
        <a-tooltip placement="top" title="Ver">
          <a class="view ml-10" @click="viewSupplier(supplier.id)">
            <a-icon slot="prefix" type="eye-svg" />
          </a>
        </a-tooltip>
      </span>
    </a-table>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="supplier.pagination.page"
        :page-size.sync="supplier.pagination.perPage"
        :total="supplier.pagination.total"
        @change="changeSupplierPage"
        @showSizeChange="changeSupplierPageSize"
      >
      </a-pagination>
    </div>
  </div>
</template>

<script>
import { format, parse } from "date-fns";
import suppliersMixins from "@/mixins/suppliers/suppliersMixins";

export default {
  name: "SuppliersList",
  mixins: [suppliersMixins],

  data() {
    return {
      suppliersTableColumns: [
        {
          title: "ID",
          class: "id",
          scopedSlots: { customRender: "id" },
        },
        {
          title: "Nome",
          class: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "E-mail",
          dataIndex: "email",
        },
        {
          title: "CPF",
          dataIndex: "cpf",
          scopedSlots: { customRender: "cpf" },
        },
        {
          title: "Celular",
          dataIndex: "mobile_phone",
          scopedSlots: { customRender: "mobilePhone" },
        },
        {
          title: "Telefone",
          dataIndex: "phone",
          scopedSlots: { customRender: "phone" },
        },
        {
          title: "Data de cadastro",
          dataIndex: "created",
          scopedSlots: { customRender: "created" },
        },
        {
          title: "Ação",
          align: "right",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ],
    };
  },
  mounted() {
    this.getSuppliers();
  },
  methods: {
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },

    deleteSupplier(id) {
      this.$http
        .post("/supplier/delete", { id })
        .then(({ data: { message } }) => {
          this.$message.success(message);
          this.getSuppliers();
        })
        .catch(
          ({
            response: {
              data: { message },
            },
          }) => {
            this.$message.error(message);
          }
        );
    },
    editSupplier(id) {
      this.$router.push(`edit/${id}`);
    },
    viewSupplier(id) {
      this.$router.push(`view/${id}`);
    },
  },
};
</script>

<style scoped></style>
